/* eslint-disable react/prop-types */
import React from "react";
import Arrow from "./Arrow";
import { Link } from "react-router-dom";

function ArrowLink(props) {
    return (
        <>
            {props.blank !== true && (
                <Link
                    to={props.linksTo}
                    className={`arrow-link ${props.classes} prevLink--${
                        props.prevLink ? "true" : "false"
                    }`}
                >
                    {props.prevLink !== true && (
                        <>
                            <span className="arrow-link__text">
                                {props.children}
                            </span>
                            <span className="arrow-link__svg">
                                <Arrow fill={props.svgFill} />
                            </span>
                        </>
                    )}
                    {props.prevLink === true && (
                        <>
                            <span className="arrow-link__svg">
                                <Arrow fill={props.svgFill} />
                            </span>
                            <span className="arrow-link__text">
                                {props.children}
                            </span>
                        </>
                    )}
                </Link>
            )}

            {props.blank === true && (
                <a
                    href={props.linksTo}
                    target={props.blank ? "_blank" : ""}
                    className={`arrow-link ${props.classes}`}
                >
                    <span className="arrow-link__text">{props.children}</span>
                    <span className="arrow-link__svg">
                        <Arrow fill={props.svgFill} />
                    </span>
                </a>
            )}
        </>
    );
}

export default ArrowLink;
