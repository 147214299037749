/* eslint-disable react/prop-types */
import React from "react";

class BlockText extends React.Component {
    render() {
        const { text, tag, className } = this.props;
        const validTags = ["small", "h1", "h2", "h3", "h4", "h5", "h6"];

        const safeTag = tag ? tag.toLowerCase() : "";
        const Text = validTags.includes(safeTag) ? safeTag : "p";

        return (
            <Text className={className}>
                {text}
                {this.props.children}
            </Text>
        );
    }
}
export default BlockText;
