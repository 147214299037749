import React from "react";
import { NavLink } from "react-router-dom";

class Navigation extends React.Component {
    render() {
        const links = ["work", "about"];
        const linkList = links.map(link => (
            <li key={link.toString()} className="site-navigation__menu-item">
                <NavLink
                    className="site-navigation__menu-link"
                    activeClassName="current"
                    strict
                    to={`/${link.toString()}`}
                >
                    {link.toString()}
                </NavLink>
            </li>
        ));

        return (
            <nav className="site-navigation">
                <ul className="site-navigation__menu">
                    {linkList}
                    <li className="site-navigation__menu-item">
                        <a
                            href="mailto:hello@jacquelinecrosley.com"
                            className="site-navigation__menu-link"
                        >
                            contact
                        </a>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Navigation;
