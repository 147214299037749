/* eslint-disable react/prop-types */
import React from "react";
import ArrowLink from "./ArrowLink";
import { truncateStr } from "../helpers";

export default function ProjectNavigation(props) {
    const projects = props.projects;
    const projectIndex = props.projectIndex;
    const prevIndex = projectIndex - 1;
    const nextIndex = projectIndex + 1;

    return (
        <article className="article project__navigation">
            <div className="container container--lg">
                {/* Project navigation */}
                <div className="grid col-align--middle">
                    <div
                        className="grid__item col-6"
                        style={{
                            display: "flex",
                            justifyContent: "flex-start"
                        }}
                    >
                        {prevIndex > -1 && (
                            <ArrowLink
                                linksTo={projects[prevIndex].projectURL}
                                svgFill="#f8d752"
                                classes="color--gray"
                                prevLink
                            >
                                {truncateStr(projects[prevIndex].name, 15)}
                            </ArrowLink>
                        )}
                    </div>
                    <div
                        className="grid__item col-6"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        {nextIndex <= 6 && (
                            <ArrowLink
                                linksTo={projects[nextIndex].projectURL}
                                svgFill="#f8d752"
                                classes="color--gray"
                                nextLink
                            >
                                {truncateStr(projects[nextIndex].name, 15)}
                            </ArrowLink>
                        )}
                    </div>
                </div>
            </div>
        </article>
    );
}
