import React, { useState, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { isInView } from "../helpers";
import Masthead from "./Masthead";
import projects from "../projects";
import BlockText from "./BlockText";
import IntroPanel from "./articles/IntroPanel";
import ArticlePanel from "./articles/ArticlePanel";
import AfterPanel from "./articles/AfterPanel";
import FloatyPanel from "./articles/FloatyPanel";
import AlternatingPanel from "./articles/AlternatingPanel";
import ProjectNavigation from "./ProjectNavigation";

const ProjectFull = props => {
    // Path
    const location = window.location.pathname;

    // Theme swapping/Observer
    const [ref, inView] = useInView({
        threshold: 0
    });

    // Popup specific
    const [open, setState] = useState(false);
    const toggle = () => {
        setState(!open);
        !open
            ? document.body.classList.add(`popOpen-true`)
            : document.body.classList.remove(`popOpen-true`);
    };

    // TODO? set empty object? Set to state? Do something so it renders without refresh.
    let project = {};
    let projectIndex = -1;

    // Project specific loading
    if (
        location !== "/" ||
        location !== "/work" ||
        location !== "/about" ||
        location !== "/contact"
    ) {
        projectIndex = projects.findIndex(x => x.projectURL === location);

        if (projectIndex !== -1) {
            project = Object.assign(projects[projectIndex]);
        }
    } else {
        project = {};
        projectIndex = -1;
    }

    return (
        <>
            <div className="observer" ref={ref}>
                <Masthead
                    styleType="thin"
                    hero={project.hero ? project.hero : ""}
                >
                    <BlockText
                        className="masthead__title"
                        text={project.name ? project.name : ""}
                        tag="h1"
                    />
                    <BlockText
                        className="masthead__description"
                        text={project.desc ? project.desc : ""}
                    />
                    {/* Popup: {state ? "true" : "false"} */}
                </Masthead>
            </div>

            <main className="site-content project">
                {isInView(inView)}

                {project.id !== "pr-05" ? (
                    <>
                        <IntroPanel project={project} toggle={toggle} />
                        <ArticlePanel project={project} />
                        <FloatyPanel project={project} />

                        {project.id !== "pr-04" && project.id !== "pr-03" && (
                            <AfterPanel
                                project={project}
                                projectIndex={projectIndex}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <IntroPanel project={project} toggle={toggle} />
                        <AlternatingPanel project={project} />
                    </>
                )}
                <ProjectNavigation
                    projects={projects}
                    projectIndex={projectIndex}
                />
            </main>
        </>
    );
};

export default ProjectFull;
