/* eslint-disable react/prop-types */
import React from "react";
import CircularText from "./CircularText";

class ProjectWindow extends React.Component {
    state = {
        x: 0,
        y: 0
    };

    handleMouseMove = e => {
        let bounding = e.currentTarget.getBoundingClientRect();
        this.setState({
            x: e.clientX - bounding.left,
            y: e.clientY - bounding.top
        });
    };

    render() {
        return (
            <button
                className="project__popup-trigger"
                onClick={this.props.clickFun}
                onMouseMove={this.handleMouseMove}
            >
                <div className="project__window">
                    <div className="project__window-inner">
                        <img
                            className="project__featured-image"
                            src={this.props.image}
                            alt={this.props.alt}
                        />
                        <CircularText
                            text="• Home Page • Home Page "
                            arc={360}
                            radius={64}
                            hasArrow={false}
                            {...this.state}
                        />
                    </div>
                </div>
                <div className="project__popup">
                    <div className="popup__inner">
                        <img
                            className="popup__image"
                            src={this.props.image}
                            alt={this.props.alt}
                        />
                    </div>
                </div>
            </button>
        );
    }
}

export default ProjectWindow;
