/* eslint-disable react/prop-types */
import React from "react";
import MediaBlock from "../MediaBlock";

export default function Alternating(props) {
    return (
        <article className="article project__alternating">
            <div className="alternating-row">
                <div className="container container--large">
                    <div className="grid">
                        <div className="grid__item col-12 sm-6">
                            <MediaBlock offset="minus-one">
                                <video autoPlay loop playsInline muted>
                                    <source
                                        src={props.project.video2}
                                        type="video/mp4"
                                    />
                                </video>
                            </MediaBlock>
                        </div>

                        <div className="grid__item col-12 sm-6">
                            <MediaBlock offset="one">
                                <img src={props.project.video3} alt="" />
                            </MediaBlock>
                        </div>
                    </div>
                </div>
            </div>
            <div className="alternating-row">
                <div className="container container--large">
                    <div className="grid">
                        <div className="grid__item col-12 sm-6">
                            <MediaBlock offset="one">
                                <video autoPlay loop playsInline muted>
                                    <source
                                        src={props.project.video4}
                                        type="video/mp4"
                                    />
                                </video>
                            </MediaBlock>
                        </div>

                        <div className="grid__item col-12 sm-6">
                            <MediaBlock offset="minus-one">
                                <video autoPlay loop playsInline muted>
                                    <source
                                        src={props.project.video5}
                                        type="video/mp4"
                                    />
                                </video>
                            </MediaBlock>
                        </div>
                    </div>
                </div>
            </div>
            <div className="alternating-row">
                <div className="container container--large">
                    <div className="grid">
                        <div className="grid__item col-12 sm-6">
                            <MediaBlock offset="minus-one">
                                <video autoPlay loop playsInline muted>
                                    <source
                                        src={props.project.video6}
                                        type="video/mp4"
                                    />
                                </video>
                            </MediaBlock>
                        </div>

                        <div className="grid__item col-12 sm-6">
                            <MediaBlock offset="one">
                                <video autoPlay loop playsInline muted>
                                    <source
                                        src={props.project.video7}
                                        type="video/mp4"
                                    />
                                </video>
                            </MediaBlock>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
}
