/* eslint-disable react/prop-types */
import React from "react";
import { useInView } from "react-intersection-observer";

export default function ProjectPanel({ children }) {
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true
    });

    const activeClass = "panel--active";

    return (
        <div className={`project-list__panel ${inView ? activeClass : ""}`}>
            <span ref={ref}></span>
            {children}
        </div>
    );
}
