/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Arrow from "./Arrow";
import Magnify from "./Magnify";

// https://codesandbox.io/s/n9bj9

const CircularText = ({ hasArrow, text, arc, radius, x, y }) => {
    const characters = text.split("");
    const degree = arc / characters.length;

    return (
        <span
            className="project__url-sugar"
            style={{ transform: `translate(${x}px, ${y}px)` }}
        >
            {hasArrow ? (
                <span className="project__url-arrow">
                    <Arrow fill="#fff" />
                </span>
            ) : (
                <span className="project__url-magnify">
                    <Magnify />
                </span>
            )}
            <span className="project__url-text">
                <span>
                    {characters.map((char, i) => (
                        <span
                            key={`heading-span-${i}`}
                            style={{
                                height: `${radius}px`,
                                transform: `rotate(${degree * i - arc / 2}deg)`,
                                transformOrigin: `0 ${radius}px 0`
                            }}
                        >
                            {char}
                        </span>
                    ))}
                </span>
            </span>
        </span>
    );
};

CircularText.propTypes = {
    text: PropTypes.string.isRequired,
    arc: PropTypes.number, // how curved do you want the text
    radius: PropTypes.number // how big do you want the curve
};

CircularText.defaultProps = {
    arc: 360,
    radius: 100
};

export default CircularText;
