// Function to help with graceful image or animation loading
export function startAnimation(callback) {
    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            callback();
        });
    });
}

// Function to check if an element is in view
export function isInView(bool) {
    const themeDark = "theme--dark";
    const themeLight = "theme--light";

    // !! Don't forget to wrap element in 'observer' !!

    bool
        ? (document.body.classList.add(themeLight),
          document.body.classList.remove(themeDark))
        : (document.body.classList.add(themeDark),
          document.body.classList.remove(themeLight));
}

// Function to truncate long strings and append '...'
export function truncateStr(str, num) {
    const ww = window.innerWidth;
    if (ww <= 540) {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}
