import React from "react";
import { BrowserRouter, withRouter, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Header from "./Header";
import Footer from "./Footer";
import Navigation from "./Navigation";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import ProjectsPage from "./ProjectsPage";
import NotFoundPage from "./NotFoundPage";
import ProjectFull from "./ProjectFull";

if (
    "ontouchstart" in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
) {
    document.body.classList.add("touch-supported");
} else {
    document.body.classList.add("no-touch-supported");
}

const AnimatedSwitch = withRouter(({ location }) => (
    <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={1000}>
            <>
                {
                    // TODO?:
                    // 1. Add 404 design/page
                }
                <div className="page-outer">
                    <div
                        className={`page-inner ${
                            location.pathname === "/" ? "home" : ""
                        }`}
                    >
                        <Header />
                        <Navigation />
                        <Switch location={location}>
                            <Route exact path="/" component={HomePage} />
                            <Route path="/about" component={AboutPage} />
                            <Route path="/work" component={ProjectsPage} />
                            <Route
                                path="/projects/:projectId"
                                component={ProjectFull}
                            />
                            <Route component={NotFoundPage} />
                        </Switch>
                        <Footer />
                    </div>
                </div>
            </>
        </CSSTransition>
    </TransitionGroup>
));

export default function Router() {
    return (
        <BrowserRouter>
            <AnimatedSwitch />
        </BrowserRouter>
    );
}
