/* eslint-disable react/prop-types */
import React from "react";
import ArrowLink from "../ArrowLink";
import BlockText from "../BlockText";
import FloatyBlock from "./../FloatyBlock";

export default function FloatyPanel(props) {
    const project = props.project;
    return (
        <article className={`article project__images ${project.id}`}>
            <div className="project__images-shifter">
                <div className="container container--lg">
                    <div className="grid project__images-grid col-just--center">
                        {/* Project 1 */}
                        {project.id === "pr-01" && (
                            <>
                                <div className="grid__item col-12 sm-5">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[0]}
                                        />
                                    </FloatyBlock>
                                </div>

                                <div className="grid__item col-12 sm-5">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[1]}
                                        />
                                    </FloatyBlock>

                                    <FloatyBlock zindex="30" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[2]}
                                        />
                                    </FloatyBlock>
                                </div>
                            </>
                        )}

                        {/* Project 2 */}
                        {project.id === "pr-02" && (
                            <>
                                <div className="grid__item col-12 ref-top-fix">
                                    <FloatyBlock zindex="0" transform={false}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[0]}
                                        />
                                    </FloatyBlock>
                                </div>
                            </>
                        )}

                        {/* Project 3 */}
                        {project.id === "pr-03" && (
                            <>
                                <div className="grid__item col-6 sm-3">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[0]}
                                        />
                                    </FloatyBlock>
                                </div>
                                <div className="grid__item col-6 sm-3">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[1]}
                                        />
                                    </FloatyBlock>
                                </div>
                                <div className="grid__item col-6 sm-3">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[2]}
                                        />
                                    </FloatyBlock>
                                </div>
                                <div className="grid__item col-6 sm-3">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[3]}
                                        />
                                    </FloatyBlock>
                                </div>

                                <div className="grid__item col-6 sm-5 ref-top-fix">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[6]}
                                        />
                                    </FloatyBlock>
                                </div>

                                <div className="grid__item col-6 sm-3">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[4]}
                                        />
                                    </FloatyBlock>
                                </div>

                                <div className="grid__item col-12 sm-4">
                                    <FloatyBlock zindex="0" transform={false}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[5]}
                                        />
                                    </FloatyBlock>
                                </div>
                            </>
                        )}

                        {/* Project 4 */}
                        {project.id === "pr-04" && (
                            <>
                                <div className="grid__item col-12 sm-4">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[0]}
                                        />
                                    </FloatyBlock>
                                </div>

                                <div className="grid__item col-12 sm-4">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[1]}
                                        />
                                    </FloatyBlock>
                                </div>

                                <div className="grid__item col-12 sm-4">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[2]}
                                        />
                                    </FloatyBlock>
                                </div>
                            </>
                        )}

                        {/* Project 6 */}
                        {project.id === "pr-06" && (
                            <>
                                <div className="grid__item col-12 sm-6">
                                    <BlockText
                                        className="article__title alternate--title"
                                        text={
                                            project.articleHeadline2
                                                ? project.articleHeadline2.toUpperCase()
                                                : ""
                                        }
                                        tag="h4"
                                    />
                                    <BlockText
                                        className="article__paragraph"
                                        text={project.articleParagraph2}
                                        tag="p"
                                    />
                                    <div className="grid col-align--middle">
                                        <div className="grid__item col-12 sm-6">
                                            <FloatyBlock
                                                zindex="0"
                                                transform={true}
                                            >
                                                <img
                                                    alt=""
                                                    src={
                                                        project.floatyImages[0]
                                                    }
                                                />
                                            </FloatyBlock>
                                        </div>
                                        <div className="grid__item col-12 sm-6">
                                            <FloatyBlock
                                                zindex="0"
                                                transform={true}
                                            >
                                                <img
                                                    alt=""
                                                    src={
                                                        project.floatyImages[1]
                                                    }
                                                />
                                            </FloatyBlock>
                                        </div>
                                        <div className="grid__item col-12 sm-6">
                                            <FloatyBlock
                                                zindex="0"
                                                transform={true}
                                            >
                                                <img
                                                    alt=""
                                                    src={
                                                        project.floatyImages[2]
                                                    }
                                                />
                                            </FloatyBlock>
                                        </div>

                                        <div className="grid__item col-12 sm-6">
                                            <FloatyBlock
                                                zindex="0"
                                                transform={true}
                                            >
                                                <img
                                                    alt=""
                                                    src={
                                                        project.floatyImages[3]
                                                    }
                                                />
                                            </FloatyBlock>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid__item col-12 sm-6 ref-top-fix">
                                    <FloatyBlock zindex="0" transform={false}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[4]}
                                        />
                                    </FloatyBlock>
                                    <span className="arrow-wrap">
                                        <ArrowLink
                                            linksTo={project.growerURLS[1]}
                                            svgFill="#4252d9"
                                            blank
                                        >
                                            {project.growerURLS[1].replace(
                                                "https://",
                                                ""
                                            )}
                                        </ArrowLink>
                                    </span>
                                </div>
                            </>
                        )}

                        {/* Project 7 */}
                        {project.id === "pr-07" && (
                            <>
                                <div className="grid__item col-12 sm-6 ref-top-fix">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[0]}
                                        />
                                    </FloatyBlock>
                                </div>

                                <div className="grid__item col-12 sm-6 ref-top-fix">
                                    <FloatyBlock zindex="0" transform={true}>
                                        <img
                                            alt=""
                                            src={project.floatyImages[1]}
                                        />
                                    </FloatyBlock>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </article>
    );
}
