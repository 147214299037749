/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

const useMove = () => {
    const [state, setState] = useState({ x: 0, y: 0 });

    const handleMouseMove = e => {
        e.persist();
        const rect = e.target.getBoundingClientRect();
        setState(state => ({
            ...state,
            x: Math.round(e.clientX - rect.left - rect.width / 2) / 10,
            y: Math.round(e.clientY - rect.top - rect.height / 2) / 10
        }));
    };

    const handleMouseLeave = e => {
        const rect = e.target.getBoundingClientRect();
        e.persist();
        setState(state => ({
            ...state,
            x: 0,
            y: 0
        }));
    };

    return {
        x: state.x,
        y: state.y,
        handleMouseMove,
        handleMouseLeave
    };
};

const FloatyBlock = props => {
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true
    });

    const activeClass = "floaty--active";
    const { x, y, handleMouseMove, handleMouseLeave } = useMove();

    return (
        <>
            {/* The current mouse position is ({x}, {y}) */}
            <div
                className={`floatyParent ${inView ? activeClass : ""}`}
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseLeave}
                onBlur={handleMouseLeave}
            >
                <span className="floatyRef" ref={ref}></span>

                <div
                    className="floatyChild"
                    style={{
                        transform: props.transform
                            ? `translate(${x}px, ${y}px)`
                            : "",
                        boxShadow: `0px 0px ${props.zindex *
                            2}px 0px rgba(0,0,0,0.25)`,
                        zindex: props.zindex
                    }}
                >
                    {props.children}
                </div>
            </div>
        </>
    );
};

export default FloatyBlock;
