/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import ArrowLink from "../ArrowLink";
import ProjectWindow from "../ProjectWindow";

export default function IntroPanel(props) {
    let skills = [];
    const project = props.project;

    if (project.skills) {
        skills = project.skills.map(skill => (
            <li className="project__skills-item" key={skill}>
                {skill}
            </li>
        ));
    }

    const hasWindow = id => {
        if (id == "pr-03" || id == "pr-04" || id == "pr-05") {
            return false;
        }
        return true;
    };

    const hasGrowsers = growerURLS => {
        if (growerURLS === undefined) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            document.body.classList.add("media-loaded");
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <article
            className={`article project__intro ${
                hasWindow(project.id) ? `has-window` : `no-window`
            }`}
        >
            <div className="container container--med">
                <div className="grid col-just--between col-align--top">
                    <div className="grid__item col-12 sm-3">
                        <ul className="project__skills-list">
                            {project.skills ? skills : ""}
                        </ul>
                    </div>

                    <div className="grid__item col-12 sm-9 md-7">
                        {project.longDesc && (
                            <p className="project__description">
                                {project.longDesc}
                            </p>
                        )}
                    </div>

                    {hasWindow(project.id) ? (
                        <ProjectWindow
                            image={project.featuredImage}
                            alt={project.featuredAlt}
                            clickFun={props.toggle}
                        />
                    ) : project.id !== "pr-05" ? (
                        <img
                            className="project__window"
                            src={project.featuredImage}
                            alt={project.featuredAlt}
                        />
                    ) : (
                        <div className="media-wrap">
                            <video
                                width="1100"
                                poster={project.poster}
                                playsInline
                                controls
                            >
                                <source src={project.video1} type="video/mp4" />
                            </video>
                        </div>
                    )}

                    {project.siteURL && (
                        <span className="arrow-wrap">
                            <ArrowLink
                                linksTo={project.siteURL}
                                svgFill="#4252d9"
                                classes=""
                                blank
                            >
                                {project.siteURL.replace("https://", "")}
                            </ArrowLink>
                        </span>
                    )}

                    {hasGrowsers(project.growerURLS) && (
                        <span className="arrow-wrap">
                            <ArrowLink
                                linksTo={project.growerURLS[0]}
                                svgFill="#4252d9"
                                blank
                            >
                                {project.growerURLS[0].replace("https://", "")}
                            </ArrowLink>
                        </span>
                    )}
                </div>
            </div>
        </article>
    );
}
