/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import CircularText from "./CircularText";

class ProjectPreview extends React.Component {
    state = {
        x: 0,
        y: 0
    };

    handleMouseMove = e => {
        let bounding = e.currentTarget.getBoundingClientRect();
        this.setState({
            x: e.clientX - bounding.left,
            y: e.clientY - bounding.top
        });
    };

    render() {
        const skills = this.props.skills.map(skill => (
            <li className="project__skills-item" key={skill}>
                {skill}
            </li>
        ));

        const hasVideo = (thumbURL, substring) => {
            return thumbURL.endsWith(substring);
        };

        return (
            <article
                className={`project project--preview ${
                    this.props.featured ? "project--featured" : ""
                }`}
                data-index={this.props.index}
                key={this.props.id}
            >
                <div
                    className="project__container--outer"
                    onMouseMove={this.handleMouseMove}
                >
                    <div className="project__container--inner">
                        <Link
                            to={this.props.projectURL}
                            className="project__url"
                        >
                            <div className={`project__background`}>
                                {hasVideo(this.props.thumbURL, ".mp4") ? (
                                    <video
                                        className="background-layer"
                                        poster={this.props.poster}
                                        playsInline
                                        autoPlay
                                        loop
                                        muted
                                    >
                                        <source
                                            src={this.props.thumbURL}
                                            type="video/mp4"
                                        />
                                    </video>
                                ) : (
                                    <div
                                        className="background-layer"
                                        style={{
                                            backgroundImage: `url(${this.props.thumbURL})`,
                                            backgroundRepeat: `no-repeat`,
                                            backgroundPosition: `center center`,
                                            backgroundSize: `101% auto`
                                        }}
                                    ></div>
                                )}
                                <CircularText
                                    text="• View Project • View Project "
                                    arc={360}
                                    radius={64}
                                    hasArrow={true}
                                    {...this.state}
                                />
                            </div>

                            <div className="project__foreground">
                                <h3 className="project__name">
                                    {this.props.name}
                                </h3>
                                <h4 className="project__tagline">
                                    {this.props.desc}
                                </h4>
                                <ul className="project__skills-list">
                                    {skills}
                                </ul>
                            </div>
                        </Link>
                    </div>
                </div>
            </article>
        );
    }
}

export default ProjectPreview;
