/* eslint-disable react/prop-types */
import React from "react";

const ContactBox = props => {
    return (
        <a
            href="mailto:hello@jacquelinecrosley.com"
            className="contact-box__link"
        >
            <div className="contact-box">
                <div className="contact-box__container">{props.children}</div>
            </div>
        </a>
    );
};

export default ContactBox;
