import poster from "./images/nckayaks-thumb.jpg";

import {
    thumbs,
    pr01_images,
    pr02_images,
    pr03_images,
    pr04_images,
    pr05_images,
    pr06_images,
    pr07_images
} from "./project-images.js";

// Object to hold all of our project data
const projects = [
    {
        id: "pr-01",
        featured: true,
        name: "NC Kayaks",
        projectURL: "/projects/nckayaks",
        desc: "Handcrafted in the PNW",
        longDesc:
            "NC Kayaks is a family-owned kayak manufacturing operation based outside of beautiful Seattle, Washington. When new owners took control of the business, they wanted to refresh their brand and digital experience but keep the reputation associated with the “NC” name.",
        siteURL: "https://nckayaks.com",
        skills: ["creative direction", "branding", "web design"],
        articleHeadline1: "Logo Refresh",
        articleHeadline2: "Product comparison",
        articleHeadline3: "Responsive",
        articleParagraph1:
            "My first step of this project was to redesign the NC Kayaks logo. The owners wanted to maintain the use of the NC acronym associated with NC Kayaks, but change the meaning from ‘Novus Composites’ to ‘North Coast’.",
        articleParagraph2:
            "With the variety of models and customizations NC offers, it was important to have a page that helps customers make the best choice for their needs.",
        articleParagraph3: "The flexible design accomodates smaller screens.",
        thumbURL: thumbs[0],
        hero: pr01_images[0],
        poster: poster,
        featuredImage: pr01_images[1],
        floatyImages: [pr01_images[4], pr01_images[6], pr01_images[8]],
        articleImage1: pr01_images[2],
        articleImage2: pr01_images[3],
        articleImage3: pr01_images[10],
        articleImage4: pr01_images[12],
        articleImage5: pr01_images[13]
    },
    {
        id: "pr-02",
        name: "Oppy Transport",
        projectURL: "/projects/oppytransport",
        featured: false,
        desc: "Moving freshness forward",
        longDesc:
            "Oppy Transport, formerly David Oppenheimer Transport, has been the transportation and logistics arm of Oppenheimer Group since the 1990's. The business strategy evolved to move beyond fresh fruit and vegetable shipments to include dry and temperature-controlled freight. With these new opprtunities in mind, there was a need to market Oppy Transport's services and bolster their visibility in the industry.",
        siteURL: "https://oppytransport.com",
        skills: ["creative direction", "branding", "web design"],
        articleHeadline1: "Branding",
        articleHeadline2: "New Carrier Sign-up Form",
        articleHeadline3: "Responsive",
        articleParagraph1:
            "Working collaboratively with other members of Oppy's marketing team, we refreshed Oppy Transport's logo. We decided to capitalize on Oppy's reputation with the Transportation's visual identiy, giving it a close resemblance.",
        articleParagraph2:
            "A crucial functionality performed by the newly-developed website is the ability for carriers to apply directly on the website through an intuitive form.",
        articleParagraph3: "The flexible design accomodates smaller screens.",
        thumbURL: thumbs[1],
        hero: pr02_images[0],
        featuredImage: pr02_images[1],
        floatyImages: [pr02_images[2]],
        articleImage3: pr02_images[3],
        articleImage4: pr02_images[4],
        articleImage5: pr02_images[5]
    },
    {
        id: "pr-03",
        name: "Ocean Spray Fresh Produce",
        projectURL: "/projects/oceanspray",
        desc: "Selected with care",
        longDesc:
            "Oppy worked to introduce the 87-year-old Ocean Spray® brand into the world of fresh produce in 2017. I worked as part of the marketing team to tailor and expand on creative originally developed by Arc Worldwide.",
        skills: ["package design", "brand support", "graphic design"],
        articleHeadline1: "Citrus Logo",
        articleParagraph1:
            "Ocean Spray's offering grew to include fresh citrus. I developed the logo to use for packaging that followed a similar layout, crafting the illustrative citrus bouquet.",
        thumbURL: thumbs[2],
        hero: pr03_images[0],
        featuredImage: pr03_images[1],
        floatyImages: [
            pr03_images[7],
            pr03_images[9],
            pr03_images[11],
            pr03_images[13],
            pr03_images[15],
            pr03_images[17],
            pr03_images[19]
        ],
        articleImage1: pr03_images[3],
        articleImage2: pr03_images[5]
    },
    {
        id: "pr-04",
        featured: true,
        name: "KeeWee",
        projectURL: "/projects/keewee",
        desc: "The super fun superfood",
        longDesc:
            "Tasked with the challenge of developing a kiwi brand to appeal to kids and their parents, our team created 'KeeWee'. I worked on illustrating the mascot character and applying this brand to a variety of pack styles and promotional materials.",
        skills: ["illustration", "branding", "package design"],
        articleHeadline1: "Sustainable Package",
        articleParagraph1:
            "The newest KeeWee pack is made with bagasse instead of traditional paper or plastic, making it recyclable, compostable and 100% tree-free.",
        thumbURL: thumbs[3],
        hero: pr04_images[0],
        featuredImage: pr04_images[1],
        floatyImages: [pr04_images[7], pr04_images[9], pr04_images[11]],
        articleImage1: pr04_images[5],
        articleImage2: pr04_images[3]
    },
    {
        id: "pr-05",
        name: "Motion Design",
        projectURL: "/projects/motiondesign",
        desc: "The magic's in the motion",
        longDesc:
            "Working in-house paired with my professional curiousity has enabled me to get my hands on a variety of projects—dabbling in animation with After Effects being one I particularly enjoy. After learning the basics and completing a few Skillshare courses, I decided to dive head-first into School of Motion’s Animation Bootcamp.",
        skills: ["personal development", "animation", "illustration"],
        thumbURL: thumbs[4],
        hero: pr05_images[0],
        poster: pr05_images[8],
        video1: pr05_images[1],
        video2: pr05_images[2],
        video3: pr05_images[3],
        video4: pr05_images[4],
        video5: pr05_images[5],
        video6: pr05_images[6],
        video7: pr05_images[7]
    },
    {
        id: "pr-06",
        name: "Grower Brand Development",
        projectURL: "/projects/branding",
        desc: "From farm to market",
        longDesc:
            "One of the many services Oppy provides to their growing partners is robust marketing support. I have led and been a part of several branding initiatives for various growers which have included the development of visual identities, logo refreshes, websites, packaging, advertisements and other collateral.",
        skills: ["creative direction", "branding", "web design"],
        articleHeadline1: "Oceanside Pole",
        articleHeadline2: "Frank & Able branding",
        articleHeadline3: "Divemex",
        articleHeadline4: "Perpetual Vegetable",
        articleParagraph1:
            "'Oceanside Pole' is a multigenerational family farm. Alongside the other members of my team, I worked to update their visual identiy.",
        articleParagraph2:
            "'Frank & Able' was a fun brand name to explore design options for. Ultimately though, none of my logos were selected by this grower but I did contribute to the overall visual identity and website design.",
        articleParagraph3:
            "Alongside the brand refresh done for Divemex, I also designed a new website to highlight it as well as a 'Pepper-UP' contest campaign.",
        articleParagraph4:
            "After establishing a new brand name and message, I worked closely with the grower family to help develop a visual identity and website to tell their story.",
        thumbURL: thumbs[5],
        hero: pr06_images[0],
        featuredImage: pr06_images[1],
        floatyImages: [
            pr06_images[6],
            pr06_images[8],
            pr06_images[10],
            pr06_images[12],
            pr06_images[14],
            pr06_images[16],
            pr06_images[18]
        ],
        articleImage1: pr06_images[2],
        articleImage2: pr06_images[4],
        articleImage3: pr06_images[16],
        articleImage4: pr06_images[18],
        growerURLS: [
            "https://oceansidepole.com",
            "https://frankandable.com",
            "https://divemexpeppers.com",
            "https://perpetualvegetable.com"
        ]
    },
    {
        id: "pr-07",
        featured: true,
        name: "Oppy 160ᵀᴴ Anniversary",
        projectURL: "/projects/oppy160",
        desc: "Bringing it for 160 years",
        longDesc:
            "Oppy's 160ᵗʰ anniversary was a momentous occasion to be a part of. I designed a variety of materials and giveaways to help celebrate this huge milestone, including a website commemorating the company's many achievements over the years.",
        siteURL: "https://oppy.com/160/",
        skills: ["creative direction", "branding", "web design"],
        articleHeadline1: "Anniversary swag",
        articleParagraph1:
            "I went with a circular badge motif to accomodate the variety of applications the emblem would be used in. I also designed several t-shirt options to give out to staff as gifts, and helped organize a parking lot party at our office to celebrate.",
        thumbURL: thumbs[6],
        hero: pr07_images[0],
        featuredImage: pr07_images[2],
        floatyImages: [pr07_images[5], pr07_images[7]],
        articleImage1: pr07_images[3],
        video: pr07_images[1],
        poster: pr07_images[9]
    }
];

export default projects;
