/* eslint-disable react/prop-types */
import React from "react";
import ProjectPanel from "./ProjectPanel";
import ProjectPreview from "./ProjectPreview";
import BlockText from "./BlockText";
import Emoji from "./Emoji";
import projects from "../projects";

class ProjectList extends React.Component {
    render() {
        const isIndex = this.props.isIndex;

        if (isIndex == true) {
            const projectList = projects
                .slice(0, 3)
                .map((project, index) => (
                    <ProjectPreview
                        featured={project.featured}
                        id={project.id}
                        key={project.id}
                        index={index}
                        name={project.name}
                        desc={project.desc}
                        thumbURL={project.thumbURL}
                        projectURL={project.projectURL}
                        skills={project.skills}
                    />
                ));
            return (
                <div className="project-list">
                    <ProjectPanel classes="large">
                        <div className="container container--large">
                            <BlockText className="headline" tag="h2">
                                <Emoji name="sparkles" />
                                &nbsp;Featured Work
                            </BlockText>
                        </div>
                        {projectList[0]}
                    </ProjectPanel>
                    <ProjectPanel>
                        {projectList[1]}
                        {projectList[2]}
                    </ProjectPanel>
                </div>
            );
        } else {
            const projectList = projects.map((project, index) => (
                <ProjectPreview
                    featured={project.featured}
                    id={project.id}
                    key={project.id}
                    index={index}
                    name={project.name}
                    desc={project.desc}
                    thumbURL={project.thumbURL}
                    projectURL={project.projectURL}
                    skills={project.skills}
                />
            ));
            return (
                <div className="project-list">
                    <ProjectPanel>{projectList[0]}</ProjectPanel>
                    <ProjectPanel>
                        {projectList[1]}
                        {projectList[2]}
                    </ProjectPanel>
                    <ProjectPanel>{projectList[3]}</ProjectPanel>
                    <ProjectPanel>
                        {projectList[4]}
                        {projectList[5]}
                    </ProjectPanel>
                    <ProjectPanel>{projectList[6]}</ProjectPanel>
                </div>
            );
        }
    }
}

export default ProjectList;
