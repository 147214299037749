import React, { useRef } from "react";
import Emoji from "./Emoji";
import Masthead from "./Masthead";
import BlockText from "./BlockText";
import ArrowLink from "./ArrowLink";
import ProjectList from "./ProjectList";

const HomePage = () => {
    let skills;
    let project = {};

    return (
        <main className="site-content home">
            <Masthead styleType="full">
                <BlockText tag="small">
                    <span>Hi!</span>
                    <Emoji name="wave" />
                    <span>My name is</span>
                </BlockText>
                <BlockText
                    className="masthead__title"
                    text={`Jacqueline Crosley.`}
                    tag="h1"
                />
                <BlockText
                    className="masthead__content"
                    text="I'm a multidisciplinary designer &amp; illustrator."
                />
            </Masthead>
            <section className="featured">
                <ProjectList isIndex={true} />
                <ArrowLink
                    linksTo="/work"
                    svgFill="#f8d752"
                    classes="color--gray"
                >
                    All Projects
                </ArrowLink>
            </section>
        </main>
    );
};

export default HomePage;
