/* eslint-disable react/prop-types */
import React from "react";
import BlockText from "../BlockText";

export default function ArticlePanel(props) {
    const project = props.project;

    return (
        <article className="article project__panel">
            <div className="container container--small">
                {project.articleHeadline1 && (
                    <BlockText
                        style={{ textlAlign: "center" }}
                        className="article__title alternate--title"
                        text={project.articleHeadline1.toUpperCase()}
                        tag="h3"
                    />
                )}

                {project.articleParagraph1 && (
                    <BlockText
                        style={{ textlAlign: "center" }}
                        className="article__paragraph"
                        text={project.articleParagraph1}
                        tag="p"
                    />
                )}
            </div>

            {project.id !== "pr-04" && project.id !== "pr-07" && (
                <div className="container container--med">
                    <div className="grid col-gutter--wide col-just--between col-align--middle product__comparison-grid">
                        {project.articleImage1 && (
                            <div className="grid__item col-5 sm-4 sm-offset-0">
                                <div className="before-container">
                                    <img
                                        className="before"
                                        alt=""
                                        src={project.articleImage1}
                                    />
                                </div>
                            </div>
                        )}
                        {project.articleImage2 && (
                            <div className="grid__item col-5 col-offset-1 sm-4 sm-offset-0">
                                <div className="after-container">
                                    <img
                                        className="after"
                                        alt=""
                                        src={project.articleImage2}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {project.id === "pr-04" && (
                <div className="container container--med">
                    <div className="grid col-gutter--wide col-just--between">
                        {project.articleImage1 && (
                            <div className="grid__item col-12 sm-6">
                                <div className="top-container">
                                    <img
                                        className="top"
                                        alt=""
                                        src={project.articleImage1}
                                    />
                                </div>
                            </div>
                        )}
                        {project.articleImage2 && (
                            <div className="grid__item col-12 sm-6">
                                <div className="bottom-container">
                                    <img
                                        className="bottom"
                                        alt=""
                                        src={project.articleImage2}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {project.id === "pr-07" && (
                <div className="container container--med">
                    <div className="grid col-gutter--wide col-just--between">
                        {project.articleImage1 && (
                            <div className="grid__item col-6 col-offset-3 sm-offset-4 sm-4">
                                <div className="top-container">
                                    <img
                                        className="top"
                                        alt=""
                                        src={project.articleImage1}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </article>
    );
}
