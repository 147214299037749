import React from "react";

const Logo = () => {
    return (
        <svg
            className="site-branding__logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 298.8 314.7"
        >
            <path d="M96.4 0v268.1l-51.6-25.3L.1 264.7l-.1.3 51.5 25.2 84.5-41.4V19.4zm83.2 255.2l23.1-11.4v-192l49.5 24.3 45-22.1-49.6-24.3L163 71.2v192.2l-27 13.3 78.8 38L259 293l-79.2-37.9z" />
            <path d="M259.2 246.2v46.7l39.6-19.5v-46.7z" />
        </svg>
    );
};

export default Logo;
