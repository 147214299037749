/* eslint-disable react/prop-types */
import React from "react";

class Emoji extends React.Component {
    render() {
        const name = this.props.name;
        const classes = this.props.classes;

        return (
            <i
                className={`em em-${name} ${classes}`}
                role="presentation"
                aria-labelledby={`Emoji ${name} icon`}
            ></i>
        );
    }
}

export default Emoji;
