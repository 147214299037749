import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";

const Header = () => (
    <header className="site-header">
        <div className="site-branding">
            <Link to="/" className="site-branding__link">
                <span className="site-branding__name">Jacqueline Crosley</span>
                <Logo />
            </Link>
        </div>
    </header>
);

export default Header;
