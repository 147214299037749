/* eslint-disable react/prop-types */
import React from "react";
import { useInView } from "react-intersection-observer";

export default function MediaBlock(props) {
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true
    });
    const activeClass = "panel--active";

    return (
        <>
            <div
                className={`media-wrap offset--${
                    props.offset
                } panel--transforms ${inView && activeClass}`}
            >
                <span ref={ref}></span>
                {props.children}
            </div>
        </>
    );
}
