/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { isInView, startAnimation } from "../helpers";

function Masthead(props) {
    const [animate, setAnimation] = useState(false);
    const [ref, inView] = useInView({
        threshold: 0
    });

    useEffect(() => {
        startAnimation(() => {
            setAnimation(true);
        });
    });

    return (
        <>
            <div className="observer" ref={ref}>
                {isInView(inView)}
                <section className={`masthead ${props.styleType}`}>
                    <div
                        className="masthead__background"
                        style={{
                            opacity: animate ? 1 : 0,
                            backgroundImage: `url(${
                                props.hero ? props.hero : ""
                            })`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "top center"
                        }}
                    ></div>
                    <div className="masthead__foreground">
                        <div className="container container--med gutter--wide">
                            {props.children}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Masthead;
