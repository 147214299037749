import React from "react";
import BlockText from "./BlockText";
import ContactBox from "./ContactBox";
import Emoji from "./Emoji";

const Footer = props => (
    <footer className="site-footer">
        <div className="container">
            <ContactBox>
                <BlockText
                    text="Don't be a stranger!"
                    className="contact-box__paragraph"
                />

                <BlockText tag="h3" className="contact-box__headline">
                    <Emoji name="writing_hand" />
                    <strong>hello</strong>
                    <span className="purple">@</span>jacquelinecrosley
                    <span className="blue">.</span>com
                </BlockText>
            </ContactBox>

            <BlockText tag="small" className="disclaimer">
                <span>&copy; 2021 Jacqueline Crosley</span>
                <Emoji name="heart" />
                <span>
                    Developed by&nbsp;
                    <a href="https://sdozeman.github.io/" target="_blank">
                        Sean Dozeman
                    </a>
                </span>
            </BlockText>
        </div>
    </footer>
);

export default Footer;
