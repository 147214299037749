// No captions on any of our media
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React from "react";
import BlockText from "../BlockText";
import ArrowLink from "../ArrowLink";
import projects from "../../projects";

export default function AfterPanel(props) {
    const project = props.project;

    return (
        <article className="article project__panel-after alignment--left">
            <div className="container container--lg">
                <div className="grid">
                    {project.id !== "pr-06" &&
                        project.id !== "pr-07" &&
                        project.id !== "pr-03" &&
                        project.id !== "pr-04" && (
                            <>
                                <div className="grid__item col-12 sm-7">
                                    <BlockText
                                        className="article__title alternate--title"
                                        text={
                                            project.articleHeadline2
                                                ? project.articleHeadline2.toUpperCase()
                                                : ""
                                        }
                                        tag="h4"
                                    />
                                    <BlockText
                                        className="article__paragraph"
                                        text={project.articleParagraph2}
                                        tag="p"
                                    />

                                    <img
                                        className=""
                                        alt=""
                                        src={project.articleImage3}
                                    />
                                </div>
                                <div className="grid__item col-12 sm-5">
                                    <div className="overlap-blocks offset-60">
                                        <BlockText
                                            className="article__title alternate--title"
                                            text={
                                                project.articleHeadline3
                                                    ? project.articleHeadline3.toUpperCase()
                                                    : ""
                                            }
                                            tag="h4"
                                        />
                                        <BlockText
                                            className="article__paragraph"
                                            text={project.articleParagraph3}
                                            tag="p"
                                        />

                                        <img
                                            className="overlap-block top left"
                                            alt=""
                                            src={project.articleImage4}
                                        />

                                        <img
                                            className="overlap-block bottom right over"
                                            alt=""
                                            src={project.articleImage5}
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                    {project.id === "pr-06" && (
                        <>
                            <div className="grid__item col-12 sm-6">
                                <BlockText
                                    className="article__title alternate--title"
                                    text={
                                        project.articleHeadline3
                                            ? project.articleHeadline3.toUpperCase()
                                            : ""
                                    }
                                    tag="h4"
                                />
                                <BlockText
                                    className="article__paragraph"
                                    text={project.articleParagraph3}
                                    tag="p"
                                />

                                <img
                                    className=""
                                    alt=""
                                    src={project.articleImage3}
                                    style={{
                                        boxShadow: `0px 0px 10px 0px rgba(0,0,0,0.125)`
                                    }}
                                />
                                <span className="arrow-wrap">
                                    <ArrowLink
                                        linksTo={project.growerURLS[2]}
                                        svgFill="#4252d9"
                                        blank
                                    >
                                        {project.growerURLS[2].replace(
                                            "https://",
                                            ""
                                        )}
                                    </ArrowLink>
                                </span>
                            </div>
                            <div className="grid__item col-12 sm-6">
                                <div className="overlap-blocks offset-60">
                                    <BlockText
                                        className="article__title alternate--title"
                                        text={
                                            project.articleHeadline4
                                                ? project.articleHeadline4.toUpperCase()
                                                : ""
                                        }
                                        tag="h4"
                                    />
                                    <BlockText
                                        className="article__paragraph"
                                        text={project.articleParagraph4}
                                        tag="p"
                                    />

                                    <img
                                        alt=""
                                        src={project.articleImage4}
                                        style={{
                                            boxShadow: `0px 0px 60px 0px rgba(0,0,0,0.25)`
                                        }}
                                    />
                                    <span className="arrow-wrap">
                                        <ArrowLink
                                            linksTo={project.growerURLS[3]}
                                            svgFill="#4252d9"
                                            blank
                                        >
                                            {project.growerURLS[3].replace(
                                                "https://",
                                                ""
                                            )}
                                        </ArrowLink>
                                    </span>
                                </div>
                            </div>
                        </>
                    )}

                    {project.id === "pr-07" && (
                        <>
                            <div className="grid__item col-12 sm-10 sm-offset-1">
                                <div className="media-wrap">
                                    <video
                                        controls
                                        poster={project.poster}
                                        src={project.video}
                                        type="video/mp4"
                                    >
                                        Sorry, your browser does not support
                                        HTML5 video.
                                    </video>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </article>
    );
}
