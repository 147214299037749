import React from "react";
import { useInView } from "react-intersection-observer";
import { isInView } from "../helpers";
import Emoji from "./Emoji";
import BlockText from "./BlockText";
import mugshot from "../images/mug.jpg";
import blurs from "../images/blurs.png";
import resume from "../JacquelineCrosley.pdf";

function AboutPage() {
    const [ref, inView] = useInView({
        threshold: 0
    });

    return (
        <main className="site-content about">
            {isInView(inView)}
            <div
                className="observer"
                ref={ref}
                style={{ transform: "translateY(-250px)" }}
            ></div>

            <section className="featured">
                <div className="container container--large gutter--wide">
                    <div className="grid col-just--around">
                        <div
                            className="grid__item col-12 md-4"
                            style={{ textAlign: "center" }}
                        >
                            <div className="image-wrap">
                                <Emoji classes="sparkles" name="sparkles" />
                                <Emoji classes="wave" name="wave" />

                                <img
                                    className="image mug"
                                    src={mugshot}
                                    alt=""
                                />
                                <img
                                    className="image blurs"
                                    src={blurs}
                                    alt="Color splotches blurring together."
                                />
                            </div>
                        </div>
                        <div className="grid__item col-12 md-6">
                            <div className="content-wrap">
                                <BlockText tag="h1">
                                    Hey it&rsquo;s me, <strong>Jackie</strong>!
                                </BlockText>
                                <BlockText>
                                    Nice to meet you! I am a multidisciplinary
                                    designer with over 10 years of experience
                                    supporting marketing initiatives in a huge
                                    variety of capacities. I’m grateful to have
                                    developed a versatile skill set, allowing me
                                    to get my hands on a wide range of design
                                    projects. Thinking big picture with the work
                                    I create is a passion of mine, and love
                                    nothing more than seeing it come to
                                    life—whether it be in the form of a
                                    functional website or in-store package.
                                </BlockText>

                                {/* <BlockText>
                                    When I’m not working I love living that PNW
                                    life with my adorable dog and husband—going
                                    for hikes, snowboarding and kayaking.
                                </BlockText> */}

                                <a
                                    href={`https://www.linkedin.com/in/jacquelinecrosley/`}
                                    className="btn"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    LinkedIn
                                </a>

                                <a
                                    href={resume}
                                    className="btn btn--negative"
                                    //download="JacquelineCrosley-Resume"
                                    target="_new"
                                >
                                    Resume
                                </a>
                            </div>
                            <div className="experience-wrap">
                                <div className="grid">
                                    <div className="grid__item col-12 md-4">
                                        <BlockText text="Experience" tag="h2" />
                                        <BlockText tag="small">
                                            Creative Services Manager
                                            <br />
                                            <strong>Oppy</strong>
                                        </BlockText>
                                        <span className="experience__date">
                                            &mdash; 2012-PRESENT
                                        </span>
                                        <BlockText tag="small">
                                            Web &amp; Graphic Design Coordinator
                                            <br />
                                            <strong>ISSofBC</strong>
                                        </BlockText>
                                        <span className="experience__date">
                                            &mdash; 2009-2012
                                        </span>
                                    </div>

                                    <div className="grid__item col-12 sm-4">
                                        <BlockText text="Education" tag="h2" />
                                        <BlockText tag="small">
                                            IDEA School of Design
                                            <br />
                                            <strong>Capilano University</strong>
                                        </BlockText>
                                        <span className="experience__date">
                                            &mdash; 2006-2009
                                        </span>
                                        <BlockText tag="small">
                                            Animation Bootcamp
                                            <br />
                                            <strong>School of Motion</strong>
                                        </BlockText>
                                        <span className="experience__date">
                                            &mdash; 2019
                                        </span>
                                    </div>

                                    <div className="grid__item col-12 sm-4">
                                        <BlockText text="Expertise" tag="h2" />
                                        <ul>
                                            <li>
                                                <small>Branding</small>
                                            </li>
                                            <li>
                                                <small>Graphic Design</small>
                                            </li>
                                            <li>
                                                <small>HTML &amp; CSS</small>
                                            </li>
                                            <li>
                                                <small>Illustration</small>
                                            </li>
                                            <li>
                                                <small>Motion Graphics</small>
                                            </li>
                                            <li>
                                                <small>Packaging</small>
                                            </li>
                                            <li>
                                                <small>Typography</small>
                                            </li>
                                            <li>
                                                <small>Video Editing</small>
                                            </li>
                                            <li>
                                                <small>Web Design</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default AboutPage;
