import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { isInView } from "../helpers";
import ProjectList from "./ProjectList";

function ProjectsPage() {
    const [ref, inView] = useInView({
        threshold: 0
    });

    return (
        <main className="site-content work">
            {isInView(inView)}
            <div
                className="observer"
                ref={ref}
                style={{ transform: "translateY(-250px)" }}
            ></div>
            <section className="featured">
                <ProjectList isIndex={false} />
            </section>
        </main>
    );
}

export default ProjectsPage;
