// Homepage images
// import thumb1 from "./images/nckayaks-thumb.jpg";
import thumb1 from "./images/projects/pr01-nckayaks.mp4";
import thumb2 from "./images/oppytransport-thumb.jpg";
import thumb3 from "./images/oceansidepole-thumb.jpg";
import thumb4 from "./images/projects/pr04-keewee@1x.jpg";
import thumb5 from "./images/projects/pr05-motion.gif";
import thumb6 from "./images/projects/pr06-growers@1x.jpg";
import thumb7 from "./images/projects/pr07-oppy160@1x.jpg";

export const thumbs = [
    `${thumb1}`,
    `${thumb2}`,
    `${thumb3}`,
    `${thumb4}`,
    `${thumb5}`,
    `${thumb6}`,
    `${thumb7}`
];

// Project 1 images
import pr01_hero from "./images/projects/pr01-00-bg.jpg";
import pr01_img1 from "./images/projects/pr01-01.jpg";
import pr01_img2 from "./images/projects/pr01-02.png";
import pr01_img3 from "./images/projects/pr01-03.png";
import pr01_img4 from "./images/projects/pr01-04@1x.jpg";
import pr01_img4x from "./images/projects/pr01-04@2x.jpg";
import pr01_img5 from "./images/projects/pr01-05@1x.jpg";
import pr01_img5x from "./images/projects/pr01-05@2x.jpg";
import pr01_img6 from "./images/projects/pr01-06@1x.jpg";
import pr01_img6x from "./images/projects/pr01-06@2x.jpg";
import pr01_img7 from "./images/projects/pr01-07@1x.jpg";
import pr01_img7x from "./images/projects/pr01-07@2x.jpg";
import pr01_img8 from "./images/projects/pr01-08.jpg";
import pr01_img9 from "./images/projects/pr01-09.jpg";
export const pr01_images = [
    `${pr01_hero}`,
    `${pr01_img1}`,
    `${pr01_img2}`,
    `${pr01_img3}`,
    `${pr01_img4}`,
    `${pr01_img4x}`,
    `${pr01_img5}`,
    `${pr01_img5x}`,
    `${pr01_img6}`,
    `${pr01_img6x}`,
    `${pr01_img7}`,
    `${pr01_img7x}`,
    `${pr01_img8}`,
    `${pr01_img9}`
];

// Project 2 images
import pr02_hero from "./images/projects/pr02-00-bg.jpg";
import pr02_img1 from "./images/projects/pr02-01.jpg";
import pr02_img2 from "./images/projects/pr02-02.jpg";
import pr02_img3 from "./images/projects/pr02-03.jpg";
import pr02_img4 from "./images/projects/pr02-04.jpg";
import pr02_img5 from "./images/projects/pr02-05.jpg";
import pr02_img6 from "./images/projects/pr02-oppyt@1x.jpg";
import pr02_img6x from "./images/projects/pr02-oppyt@2x.jpg";

export const pr02_images = [
    `${pr02_hero}`,
    `${pr02_img1}`,
    `${pr02_img2}`,
    `${pr02_img3}`,
    `${pr02_img4}`,
    `${pr02_img5}`,
    `${pr02_img6}`,
    `${pr02_img6x}`
];

// Project 3 images
import pr03_hero from "./images/projects/pr03-00-bg.jpg";
import pr03_img1 from "./images/projects/pr03-01@1x.jpg";
import pr03_img1x from "./images/projects/pr03-01@2x.jpg";
import pr03_img2 from "./images/projects/pr03-02@1x.png";
import pr03_img2x from "./images/projects/pr03-02@2x.png";
import pr03_img3 from "./images/projects/pr03-03@1x.png";
import pr03_img3x from "./images/projects/pr03-03@2x.png";
import pr03_img4 from "./images/projects/pr03-04@1x.jpg";
import pr03_img4x from "./images/projects/pr03-04@2x.jpg";
import pr03_img5 from "./images/projects/pr03-05@1x.jpg";
import pr03_img5x from "./images/projects/pr03-05@2x.jpg";
import pr03_img6 from "./images/projects/pr03-06@1x.jpg";
import pr03_img6x from "./images/projects/pr03-06@2x.jpg";
import pr03_img7 from "./images/projects/pr03-07@1x.jpg";
import pr03_img7x from "./images/projects/pr03-07@2x.jpg";
import pr03_img8 from "./images/projects/pr03-08@1x.png";
import pr03_img8x from "./images/projects/pr03-08@2x.png";
import pr03_img9 from "./images/projects/pr03-09@1x.jpg";
import pr03_img9x from "./images/projects/pr03-09@2x.jpg";
import pr03_img10 from "./images/projects/pr03-10@1x.png";
import pr03_img10x from "./images/projects/pr03-10@2x.png";
import pr03_img11 from "./images/projects/pr03-osp@1x.jpg";
import pr03_img11x from "./images/projects/pr03-osp@2x.jpg";

export const pr03_images = [
    `${pr03_hero}`,
    `${pr03_img1}`,
    `${pr03_img1x}`,
    `${pr03_img2}`,
    `${pr03_img2x}`,
    `${pr03_img3}`,
    `${pr03_img3x}`,
    `${pr03_img4}`,
    `${pr03_img4x}`,
    `${pr03_img5}`,
    `${pr03_img5x}`,
    `${pr03_img6}`,
    `${pr03_img6x}`,
    `${pr03_img7}`,
    `${pr03_img7x}`,
    `${pr03_img8}`,
    `${pr03_img8x}`,
    `${pr03_img9}`,
    `${pr03_img9x}`,
    `${pr03_img10}`,
    `${pr03_img10x}`,
    `${pr03_img11}`,
    `${pr03_img11x}`
];

// Project 4 images
import pr04_hero from "./images/projects/pr04-00-bg.jpg";
import pr04_img1 from "./images/projects/pr04-01@1x.jpg";
import pr04_img1x from "./images/projects/pr04-01@2x.jpg";
import pr04_img2 from "./images/projects/pr04-02@1x.jpg";
import pr04_img2x from "./images/projects/pr04-02@2x.jpg";
import pr04_img3 from "./images/projects/pr04-03@1x.png";
import pr04_img3x from "./images/projects/pr04-03@2x.png";
import pr04_img4 from "./images/projects/pr04-04@1x.png";
import pr04_img4x from "./images/projects/pr04-04@2x.png";
import pr04_img5 from "./images/projects/pr04-05@1x.png";
import pr04_img5x from "./images/projects/pr04-05@2x.png";
import pr04_img6 from "./images/projects/pr04-06@1x.png";
import pr04_img6x from "./images/projects/pr04-06@2x.png";

export const pr04_images = [
    `${pr04_hero}`,
    `${pr04_img1}`,
    `${pr04_img1x}`,
    `${pr04_img2}`,
    `${pr04_img2x}`,
    `${pr04_img3}`,
    `${pr04_img3x}`,
    `${pr04_img4}`,
    `${pr04_img4x}`,
    `${pr04_img5}`,
    `${pr04_img5x}`,
    `${pr04_img6}`,
    `${pr04_img6x}`
];

// Project 5 images
import pr05_hero from "./images/projects/pr05-00-bg.jpg";
import pr05_video1 from "./images/projects/pr05-01.mp4";
import pr05_video2 from "./images/projects/pr05-02.mp4";
import pr05_video3 from "./images/projects/pr05-03.gif";
import pr05_video4 from "./images/projects/pr05-05.mp4";
import pr05_video5 from "./images/projects/pr05-04.mp4";
import pr05_video6 from "./images/projects/pr05-06.mp4";
import pr05_video7 from "./images/projects/pr05-07.mp4";
import pr05_poster from "./images/projects/pr05-01.jpg";

export const pr05_images = [
    `${pr05_hero}`,
    `${pr05_video1}`,
    `${pr05_video2}`,
    `${pr05_video3}`,
    `${pr05_video4}`,
    `${pr05_video5}`,
    `${pr05_video6}`,
    `${pr05_video7}`,
    `${pr05_poster}`
];

// Project 6 images
import pr06_hero from "./images/projects/pr06-00-bg.jpg";
import pr06_img1 from "./images/projects/pr06-01.jpg";
import pr06_img2 from "./images/projects/pr06-02@1x.png";
import pr06_img2x from "./images/projects/pr06-02@2x.png";
import pr06_img3 from "./images/projects/pr06-03@1x.png";
import pr06_img3x from "./images/projects/pr06-03@2x.png";
import pr06_img4 from "./images/projects/pr06-04@1x.png";
import pr06_img4x from "./images/projects/pr06-04@2x.png";
import pr06_img5 from "./images/projects/pr06-05@1x.png";
import pr06_img5x from "./images/projects/pr06-05@2x.png";
import pr06_img6 from "./images/projects/pr06-06@1x.png";
import pr06_img6x from "./images/projects/pr06-06@2x.png";
import pr06_img7 from "./images/projects/pr06-07@1x.png";
import pr06_img7x from "./images/projects/pr06-07@2x.png";
import pr06_img8 from "./images/projects/pr06-08@1x.jpg";
import pr06_img8x from "./images/projects/pr06-08@2x.jpg";
import pr06_img9 from "./images/projects/pr06-09@1x.jpg";
import pr06_img9x from "./images/projects/pr06-09@2x.jpg";
import pr06_img10 from "./images/projects/pr06-10@1x.jpg";
import pr06_img10x from "./images/projects/pr06-10@2x.jpg";

export const pr06_images = [
    `${pr06_hero}`,
    `${pr06_img1}`,
    `${pr06_img2}`,
    `${pr06_img2x}`,
    `${pr06_img3}`,
    `${pr06_img3x}`,
    `${pr06_img4}`,
    `${pr06_img4x}`,
    `${pr06_img5}`,
    `${pr06_img5x}`,
    `${pr06_img6}`,
    `${pr06_img6x}`,
    `${pr06_img7}`,
    `${pr06_img7x}`,
    `${pr06_img8}`,
    `${pr06_img8x}`,
    `${pr06_img9}`,
    `${pr06_img9x}`,
    `${pr06_img10}`,
    `${pr06_img10x}`
];

// Project 7 images
import pr07_hero from "./images/projects/pr07-00-bg.jpg";
import pr07_img1 from "./images/projects/pr07-01.jpg";
import pr07_img2 from "./images/projects/pr07-02@1x.png";
import pr07_img2x from "./images/projects/pr07-02@2x.png";
import pr07_img3 from "./images/projects/pr07-03@1x.jpg";
import pr07_img3x from "./images/projects/pr07-03@2x.jpg";
import pr07_img4 from "./images/projects/pr07-04@1x.jpg";
import pr07_img4x from "./images/projects/pr07-04@2x.jpg";
import pr07_video from "./images/projects/pr07-05-160celebration.mp4";
import pr07_videoPoster from "./images/projects/pr07-poster@1x.jpg";

export const pr07_images = [
    `${pr07_hero}`,
    `${pr07_video}`,
    `${pr07_img1}`,
    `${pr07_img2}`,
    `${pr07_img2x}`,
    `${pr07_img3}`,
    `${pr07_img3x}`,
    `${pr07_img4}`,
    `${pr07_img4x}`,
    `${pr07_videoPoster}`
];
