/* eslint-disable react/prop-types */
import React from "react";

function Arrow(props) {
    return (
        <svg
            version="1.1"
            id="renders"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 65.7 32.7"
        >
            <path fill={props.fill ? props.fill : ""} d="M0 14.4h61.3v4H0z" />
            <path
                fill={props.fill ? props.fill : ""}
                d="M48.1 32.7l-2.8-2.9 14.5-13.4L45.3 2.9 48.1 0l17.6 16.4z"
            />
        </svg>
    );
}

export default Arrow;
